<template>
  <v-card class="my-4" elevation="4">
    <v-card-title><v-icon class="mr-2">mdi-login</v-icon>Login social</v-card-title>
    <v-card-text class="mx-2 py-0">
      <tooltip-switch
        class-props="mt-1"
        :editable="true"
        :right="true"
        :value.sync="active"
        label="Login com Google"
        :tip="appFlagTip"
        @update:value="value => (this.active = value)"
      />
    </v-card-text>
    <v-card-actions class="pl-0">
      <v-spacer />
      <v-btn color="primary" :disabled="loading" @click="handleUpdateSocialLogin"><v-icon class="mr-1">sync</v-icon>Atualizar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { QUERY_CONFIGURATION, MUTATION_UPDATE_LOGIN_GOOGLE } from '@/modules/accounts/graphql'
export default {
  components: {
    TooltipSwitch: () => import('@/components/base/TooltipSwitch')
  },
  data: () => ({
    active: false,
    accountId: null,
    appFlagTip: 'Só habilite este recurso caso o login com o google esteja totalmente configurado e funcionando no app.'
  }),
  apollo: {
    getConfiguration: {
      query: QUERY_CONFIGURATION,
      fetchPolicy: 'network-only',
      variables() {
        return { account_id: this.accountId }
      },
      context() {
        return {
          uri: this.$microservicesUrls['accounts'],
          headers: {
            authorization: localStorage.getItem('session_id')
          }
        }
      },
      result({ data }) {
        this.active = data.configuration.login_google_config.enable_login_with_google
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    refresh() {
      this.$apollo.queries.getConfiguration.refresh()
    },
    async handleUpdateSocialLogin() {
      const updateGoogleLoginVariables = {
        accountId: this.accountId,
        input: {
          enableLoginWithGoogle: this.active
        }
      }
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_LOGIN_GOOGLE,
          variables: updateGoogleLoginVariables,
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            }
          }
        })
        this.$snackbar({ message: 'Login social atualizado com sucesso', snackbarColor: '#2E7D32' })
      } catch (err) {
        this.$snackbar({ message: 'Falha ao atualizar login social', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
